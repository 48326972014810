<template>
    <div id="Templates" :class="{isFilterOpen: checkFilterOpen}">
        <loader-component v-if="showLoader"></loader-component>
        <mds-layout-grid>
            <mds-row style="margin: 0; padding: 0">
                <div
                    class="leftBlock"
                    :class="{ showFilterLeftBlock: showFilterPanel }"
                    :style="cssVariables"
                >
                    <div class="templatesHeaderSection">
                        <div>
                            <h2 class="templates-header">Templates</h2>
                        </div>
                        <div
                            class="headerActionButton"
                            style="margin-top: 25px"
                        >
                            <header-button-panel
                                :buttonDetails="buttonPanelDetails"
                                :counterDetails="counterDetails"
                                @panelButtonClicked="onClickPanelButton"
                            ></header-button-panel>
                        </div>
                    </div>
                    <div class="templatesTable">
                        <div class="template_table">
                            <!-- <div> -->
                            <mds-table
                                multiselection
                                row-hover
                                show-sortable
                                v-if="templateLength > 0"
                            >
                                <mds-thead
                                    @mds-thead-multiselection-changed="
                                        handleMultiSelection($event)
                                    "
                                    :indeterminate="
                                        selectionStatus.isIndeterminate
                                    "
                                    :checked="selectionStatus.isAllSelected"
                                >
                                    <mds-th
                                        v-for="(
                                            header, index
                                        ) in templatesHeader"
                                        :key="index"
                                        :sortable="header.sortable"
                                        :sorted="header.sorted"
                                        :width="header.width"
                                        :right-aligned="
                                            header.align === 'right'
                                        "
                                        @mds-th-sort-changed="
                                            handleSortEvent(
                                                index,
                                                header.fieldName,
                                                $event
                                            )
                                        "
                                    >
                                        {{ header.text }}
                                    </mds-th>
                                </mds-thead>
                                <mds-tbody>
                                    <mds-tr
                                        v-for="(row, index) in templatesRow"
                                        :key="index"
                                        :checked="row.checked"
                                        :multiselection-label="row.name"
                                        @mds-tr-multiselection-changed="
                                            handleRowSelectEvent(
                                                index,
                                                $event,
                                                row
                                            )
                                        "
                                    >
                                        <mds-td
                                            v-for="(
                                                header, i
                                            ) in templatesHeader"
                                            :key="i"
                                            :right-aligned="
                                                header.align === 'right'
                                            "
                                        >
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'actions'
                                                "
                                            >
                                                <span
                                                    ><mds-button
                                                        v-if="
                                                            row.isedit == true
                                                        "
                                                        :disabled="
                                                            editActionButton
                                                                || !(permissions.includes('templates-edit')
                                                                || permissions.includes('*'))
                                                        "
                                                        v-on:click="
                                                            singleEditActionButton(
                                                                row
                                                            )
                                                        "
                                                        class="actionButton"
                                                        icon="pencil"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                                <span>
                                                    <mds-button
                                                        v-if="
                                                            row.isedit == false
                                                        "
                                                        :disabled="
                                                            viewActionButton
                                                                || !(permissions.includes('templates-view')
                                                                || permissions.includes('*'))
                                                        "
                                                        v-on:click="
                                                            singleViewActionButton(
                                                                row
                                                            )
                                                        "
                                                        class="actionButton"
                                                        icon="eye"
                                                        variation="icon-only"
                                                        type="button"
                                                    >
                                                    </mds-button
                                                ></span>
                                                <span
                                                    ><mds-button
                                                        :disabled="
                                                            row.isduplicate == false ||
                                                            (duplicateActionButton
                                                                || !(permissions.includes('templates-duplicate')
                                                                || permissions.includes('*')))
                                                        "
                                                        @click="
                                                            singleDuplicateActionButton(
                                                                row
                                                            )
                                                        "
                                                        class="actionButton"
                                                        icon="open-new"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                                <span
                                                    ><mds-button
                                                        :id="row.templateid"
                                                        :disabled="
                                                            !row.isshare ||
                                                            (shareActionButton
                                                                || !(permissions.includes('templates-share')
                                                                || permissions.includes('*')))
                                                        "
                                                        @click="
                                                            singalShareActionButton(
                                                                row
                                                            )
                                                        "
                                                        class="actionButton"
                                                        icon="share"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                                <span
                                                    v-if="
                                                        row.templateid ===
                                                        toggleID
                                                            ? true
                                                            : false
                                                    "
                                                >
                                                    <popover-component
                                                        v-if="
                                                            togglerowSharePopover
                                                        "
                                                        :popOverSelection="
                                                            popOverSelection
                                                        "
                                                        width="200px"
                                                        position="bottom-center"
                                                        :triggerBy="
                                                            triggerByPopover(
                                                                row.templateid
                                                            )
                                                        "
                                                        v-model="
                                                            togglerowSharePopover
                                                        "
                                                        @clickOnShareData="
                                                            clickOnShareData
                                                        "
                                                        @clickOnPublishData="
                                                            clickOnPublishData
                                                        "
                                                        @close="
                                                            togglerowSharePopover = false
                                                        "
                                                    ></popover-component>
                                                </span>
                                                <span
                                                    ><mds-button
                                                        :disabled="
                                                            !row.isdelete ||
                                                            (deleteActionButton
                                                                || !(permissions.includes('templates-delete')
                                                                || permissions.includes('*')))
                                                        "
                                                        v-on:click="
                                                            singleDeleteActionButton(
                                                                row
                                                            )
                                                        "
                                                        class="actionButton"
                                                        icon="trash"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                            </template>
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'updateddate'
                                                "
                                            >
                                                {{
                                                    convertDate(
                                                        row[header.fieldName]
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{ row[header.fieldName] }}
                                            </template>
                                        </mds-td>
                                    </mds-tr>
                                </mds-tbody>
                            </mds-table>
                            
                        <mds-empty-state
                            v-if="templatesRow == undefined"
                            class="no-results-message"
                            size="medium"
                            title="No results matched"
                            message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                        ></mds-empty-state>
                        <!-- </div> -->
                        </div>
                        <mds-pagination
                            :class="[
                                showFilterPanel === true
                                    ? 'move-pagination-show-info'
                                    : '',
                                'pagination-component',
                            ]"
                            v-if="templateLength"
                            :key="paginationComponentKey"
                            show-items-info
                            show-items-select
                            :total-items="totalItems"
                            :pageSize="pageSize"
                            :pageSizes="[10, 20, 50]"
                            @mds-pagination-page-changed="paginateTable"
                        ></mds-pagination>
                    </div>
                </div>

                <div
                    class="rightBlock"
                    :class="{ showFilterRightBlock: showFilterPanel }"
                    :style="cssVariables"
                >
                    <div class="filterPanel">
                        <transition-group name="fade" class="group-4">
                            <filter-panel
                                ref="fiterpanel"
                                :key="checkBoxFilterKey"
                                :filterData="filterObject"
                                @filterEvent="searchFilter"
                                @multiSelectFilterChange="
                                    multiSelectFilterChange
                                "
                                @hideFilterPanel="closeFilterPanel"
                                :isFilterPanelOpen="showFilterPanel"
                                searchInputPlaceHolder="Search templates..."
                                @showFilterPanel="openFilterPannel"
                                :selectCheckboxId="checkboxFilterId"
                                :showSearchInput="true"
                                @clearAllFilters="clearAllFilters"
                                @clearInputFilter="clearTextFilter"
                                searchInputLable="Templates Name"
                            ></filter-panel>
                        </transition-group>
                    </div>
                </div>
            </mds-row>
        </mds-layout-grid>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
        <!-- share option popover -->
        <popover-component
            v-if="toggleSharePopover"
            :popOverSelection="popOverSelection"
            width="200px"
            position="bottom-center"
            triggerBy="ShareButtonId"
            v-model="toggleSharePopover"
            @clickOnShareData="clickOnShareData"
            @clickOnPublishData="clickOnPublishData"
            @close="toggleSharePopover = false"
        ></popover-component>
        <dialog-popup
            :toggleDialog="toggleDialog"
            :title="dialogTitle"
            :sub-title="subTitle"
            :selectedAction="selectedAction"
            checkboxLabel="Yes, delete this template"
            deleteLabel="template"
            @cancel="closeDialogPopup"
            @emitDeletePopup="deletePopupModal"
            @emitSaveModalPopup="emitSaveModalPopup"
            @emitPublishPopup="publishYes"
        >
        </dialog-popup>
        <modal-component
            width="600px"
            title="Share Template"
            :toggleModalComponent="toggleModalComponent"
            :getselectionOfUser="getselectionOfUser"
            :select-multi-row-data="selectMultiRowData"
            :getShareDataSetData="getShareTemplateData"
            @saveModalComponent="saveModalComponent"
            @addSharedUser="AddSharedUser"
            @selectedEntityDeleteButton="selectedEntityDeleteButton"
            @close="closeSharePopUp"
        />
    </div>
</template>

<script>
import get from "lodash/get";
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import { MdsButton } from "@mds/button";
import store from "@/store";
import { REPORTING_ACTIONS } from "@/store/modules/reporting/constants";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import FilterPanel from "../common_components/FilterPanel.vue";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import MdsPagination from "@mds/pagination";
import HeaderButtonPanel from "../common_components/HeaderButtonPanel.vue";
import MdsEmptyState from "@mds/empty-state";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DialogPopup from "../common_components/DialogPopup";
import PopoverComponent from "../ui_component/popoverComponent.vue";
import {
    deleteTemplateData,
    duplicateTemplateData,
    publishTemplate,
    shareTemplate,
} from "../../services/reporting_service.js";
import ModalComponent from "../ui_component/ModalComponent.vue";
import { dateFormatterMixin } from "../../mixins/date.js";

export default {
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        MdsButton,
        MdsPagination,
        FilterPanel,
        NotificationComponent,
        LoaderComponent,
        HeaderButtonPanel,
        MdsEmptyState,
        DialogPopup,
        PopoverComponent,
        ModalComponent,
    },
    beforeCreate() {
        this.$store = store;
    },
    mixins: [dateFormatterMixin],

    data() {
        return {
            shareUniverseTableHover: true,
            searchTextInputField: "",
            currentActive: 1,
            checkboxFilterId: [],
            showFilterPanel: false,
            isFilterPanelOpen: true,
            showEnableDisable: true,
            checkBoxFilterKey: 0,
            toggleID: 0,
            universenameduplicate: "",
            toggleSharePopover: false,
            toggleShare: false,
            toggleModalComponent: false,
            toggleDuplicate: false,
            toggleDialog: false,
            togglePublish: false,
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            showLoader: true,
            popOverSelection: "sharePopover",
            togglerowSharePopover: false,
            selectMultiRowData: [],
            itemTotalCount: new Set(),
            editActionButton: false,
            viewActionButton: false,
            shareActionButton: false,
            deleteActionButton: false,
            deletePublishActionButton: true,
            duplicateActionButton: false,
            sortType: false,
            createUniverseTitle: "Create Template",
            getData: [],
            page: 1,
            pageSize: 10,
            totalItems: 0,
            sortValue: false,
            paginationComponentKey: 0,
            clearCheckboxFilter: false,
            checkboxFilterOwner: [],
            templateSharedPublishFilter: "",
            checkboxFilterSharedPublish: [],
            searchFilterText: "",
            rulesTableKey: 0,
            /**sorting */
            sortOrder: -1,
            sortColIndex: 4,
            orderByColumn: "updateddate",
            deleteSelectedItems: [],
            duplicateSelectedItems: [],
            selectedOwnerItems: "",
            selectedAction: "",
            subTitle: `This action cannot be undone, and the template
        will be deleted for all users with access`,
            dialogTitle: "Delete this template?",
            editSelectedItems: [],

            counterDetails: {
                displayCounterDetails: true,
                count: 0,
                countText: "Selected",
                showVerticalLine: true,
            },

            buttonPanelDetails: [
                {
                    id: "AddButtonId",
                    buttonName: "Create Template",
                    iconName: "plus",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: false,
                    isText: false,
                    permission: "templates-create"
                },
                {
                    id: "DuplicateButtonId",
                    buttonName: "Duplicate",
                    iconName: "open-new",
                    iconColor: "defaultColor",
                    isDisabled: true,
                    showVerticalLine: false,
                    isText: false,
                    permission: "templates-duplicate"
                },
                {
                    id: "ShareButtonId",
                    buttonName: "Share",
                    iconName: "share",
                    isDisabled: true,
                    iconColor: "defaultColor",
                    showVerticalLine: false,
                    isText: false,
                    permission: "templates-share"
                },
                {
                    id: "DeleteButtonId",
                    buttonName: "Delete",
                    iconName: "trash",
                    iconColor: "defaultColor",
                    isDisabled: true,
                    showVerticalLine: false,
                    isText: false,
                    permission: "templates-delete"
                },
            ],

            templatesHeader: [
                {
                    fieldName: "templatename",
                    text: "Name",
                    width: "170px",
                    sortable: true,
                },
                {
                    fieldName: "datasetname",
                    text: "Data Set",
                    width: "170px",
                    sortable: true,
                },
                {
                    fieldName: "owner",
                    text: "Owner",
                    width: "210px",
                    sortable: true,
                },
                {
                    fieldName: "sharedpublishstatus",
                    text: "Shared/Public",
                    width: "120px",
                    sortable: true,
                },
                {
                    fieldName: "updateddate",
                    text: "Last Updated",
                    width: "150px",
                    sortable: true,
                    sorted: -1,
                },
                {
                    fieldName: "updatedby",
                    text: "Updated By",
                    width: "210px",
                    sortable: true,
                },
                {
                    fieldName: "actions",
                    text: "Actions",
                    width: "140px",
                    align: "right",
                },
            ],

            templatesRow: [],
            prevSelectedItems: [],
            filterObject: [
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
            ],
            getselectionOfUser: [],
            getShareTemplateData: [],
            addPermission: [],
            removePermission: [],
            checkFilterOpen: false
        };
    },

    props: {
        menuwidth: {
            type: String,
        },
    },
    watch: {
        clearCheckboxFilter() {
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.clearCheckboxFilter = false;
        },
        templateOwnersList(){
            this.getTemplatesOwnerFilter([]);
        }
    },

    async mounted() {
        await this.filterTemplateList({
            searchOwner: this.selectedOwnerItems,
            searchStatus: this.templateSharedPublishFilter,
            searchTemplate: this.searchFilterText,
            orderByColumn: this.orderByColumn,
        });
        this.showLoader = true;
        await this.$store.dispatch(REPORTING_ACTIONS.FETCH_TEMPLATE_OWNER_LIST);
        await this.cachedDispatch(REPORTING_ACTIONS.FETCH_USER_LIST);
        
        this.getAllDataSetUserLists();
        this.showLoader = false;
    },

    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapGetters("reporting", [
            "getShareTemplateInformation",
        ]),
        ...mapState({
            templateOwnersList(state) {
                return get(
                    state,
                    "reportingModule.templateOwnerList.templateOwnerList",
                    []
                );
            },
            fetchTemplateList(state) {
                return get(state, "reportingModule.templates.templates", {});
            },
            getAllUserList(state) {
                return get(
                    state,
                    "reportingModule.userList.userList",
                    []
                );
            },
        }),
        topDuplicateIcon() {
            return this.buttonPanelDetails.filter(
                (x) => x.id === "DuplicateButtonId"
            );
        },
        topShareIcon() {
            return this.buttonPanelDetails.filter(
                (x) => x.id === "ShareButtonId"
            );
        },
        topDeleteIcon() {
            return this.buttonPanelDetails.filter(
                (x) => x.id === "DeleteButtonId"
            );
        },

        selectionStatus() {
            // filter your rows by the checked property
            const filtered = this.templatesRow.filter(
                (row) => row.checked === true
            );

            // isIndeterminate = there are more than one rows checked but not all
            // isAllSelected = all rows are checked
            return {
                isIndeterminate:
                    filtered.length > 0 &&
                    filtered.length < this.templatesRow.length,
                isAllSelected:
                    filtered.length === this.templatesRow.length &&
                    filtered.length != 0 &&
                    this.templatesRow != 0,
            };
        },

        cssVariables() {
            return {
                "--menuWidth": this.menuwidth,
            };
        },

        templateLength() {
            return this.templatesRow?.length;
        },

        permissions () {
            return this.$store.state.auth.permissions;
        },
    },

    methods: {
        ...mapActions("reporting", [
            "fetchSharedTemplateInformation",
        ]),
        ...mapMutations("reporting", ["setSelectedItemstoDelete"]),

        async getTemplateResponse(param) {
            let response = await this.fetchTemplateList.templates;
            const templateList = response;
            if (param.size) {
                for (let item of param) {
                    for (let i =0; i < templateList.length; i++) {
                        if (templateList[i].templateid === item.templateid) {
                            templateList[i] = item;
                        }
                    }
                }
            }
            this.templatesRow = templateList;
            if(this.templatesRow.length === 0){
                this.templatesRow = undefined;
            }
            this.totalItems = this.fetchTemplateList.total;
        },

        getAllDataSetUserLists() {
            let response = this.getAllUserList.data;
            if (response) {
                for (let i = 0; i < response.length; i++) {
                    let data = {};
                    data.value = response[i].email;
                    data.text = response[i].email;
                    this.getselectionOfUser.push(data);
                }
            }
        },
        triggerByPopover(param) {
            return param.toString();
        },

        AddSharedUser({ selectedUser, accessInput }) {
            let response = this.getShareTemplateData.filter(
                (item) => item.name == selectedUser
            );
            if (response.length > 0) {
                response.forEach((item) => {
                    item.access = accessInput;
                });
                let obj = {};
                obj.email = selectedUser;
                if (accessInput == "Edit") {
                    obj.isEdit = true;
                } else if (accessInput != "Edit") {
                    obj.isEdit = false;
                }
                this.addPermission.push(obj);
            } else if (response.length == 0) {
                let data = {};
                data.name = selectedUser;
                data.access = accessInput;
                data.button = "delete";
                this.getShareTemplateData.push(data);
                let obj = {};
                obj.email = selectedUser;
                if (accessInput == "Edit") {
                    obj.isEdit = true;
                } else if (accessInput != "Edit") {
                    obj.isEdit = false;
                }
                this.addPermission.push(obj);
            }
        },

        closeSharePopUp() {
            this.toggleSharePopover = false;
            this.toggleModalComponent = false;
            // this.reset();
        },

        selectedEntityDeleteButton(name) {
            let response = this.getShareTemplateData.filter(
                (item) => item.name == name
            );
            this.getShareTemplateData = this.getShareTemplateData.filter(
                (item) => item.name !== name
            );
            this.addPermission = this.addPermission.filter(
                (item) => item.email !== name
            );

            response.forEach((item) => {
                var obj = {};
                obj.email = item.name;
                if (item.access == "Edit") {
                    obj.isEdit = true;
                } else if (item.access == "Read Only") {
                    obj.isEdit = false;
                }
                this.removePermission.push(obj);
            });
        },

        /**This logic given in MDS documentaion so added in seperate function */
        executeSortingLogicForMdsTable(colIndex) {
            // if your tracked sort order is 0 (none), set it 1 (ascending)
            // if there is already an order being tracked inverse it
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            // if the table was already sorted by a different column, then reset that column to 0 (none)
            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(this.templatesHeader[this.sortColIndex], "sorted", 0);
                this.sortOrder = -1;
            }

            // track the current column as the one being sorted on
            this.sortColIndex = colIndex;
            this.$set(this.templatesHeader[colIndex], "sorted", this.sortOrder);
        },

        handleSortEvent(colIndex, colName) {
            this.executeSortingLogicForMdsTable(colIndex);
            this.orderByColumn = colName;
            this.sortOrder == 1
                ? (this.sortType = true)
                : (this.sortType = false);
            this.showLoader = true;
            this.page = 1;
            this.paginationComponentKey += 1;
            this.filterTemplateList({
                orderByColumn: this.orderByColumn,
                isAscending: this.sortType,
                searchOwner: this.selectedOwnerItems,
                searchStatus: this.templateSharedPublishFilter,
                searchTemplate: this.searchFilterText,
                page: this.page,
                pageSize: this.pageSize,
                prevSelectedItems: this.clearCheckboxFilter ? [] : this.itemTotalCount
            });
        },

        getTemplatesOwnerFilter(selectedFilter) {
            let response = this.templateOwnersList;
            if (response !== undefined) {
                this.filterObject[0].filterHeader = "Owner";
                this.filterObject[0].selectionType = "multiple";
                const data =
                    response &&
                    response.reduce((obj, item, index) => {
                        const id = index;
                        const lable = item.email;
                        obj.push({ id, lable, isChecked: false });
                        return obj;
                    }, []);
                data.map(x => {
                    selectedFilter.forEach(y => {
                        if (x.lable === y.value.lable) {
                            x.isChecked = true;
                        }
                    })
                });
                this.filterObject[0].filterList = data;
            }
        },

        getTemplatesSharePublishFilter(selectedFilter) {
            this.filterObject[1].filterHeader = "Shared/Public";
            this.filterObject[1].selectionType = "multiple";
            let data = [
                {
                    lable: "Private",
                    id: 1,
                    isChecked: false,
                },
                {
                    lable: "Public",
                    id: 2,
                    isChecked: false,
                },
                {
                    lable: "Shared",
                    id: 3,
                    isChecked: false,
                },
                {
                    lable: "Shared/Public",
                    id: 4,
                    isChecked: false,
                },
            ];
            data.map((x) => {
                selectedFilter.forEach((y) => {
                    if (x.lable === y.value.lable) {
                        x.isChecked = true;
                    }
                });
            });
            this.filterObject[1].filterList = data;
        },

        async paginateTable(arg) {
            this.showLoader = true;
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.selectedDataMatched();
        },

        selectedDataMatched() {
            this.filterTemplateList({
                searchOwner: this.selectedOwnerItems,
                searchStatus: this.templateSharedPublishFilter,
                searchTemplate: this.searchFilterText,
                page: this.page,
                pageSize: this.pageSize,
                prevSelectedItems: this.clearCheckboxFilter ? [] : this.itemTotalCount
            });
            this.handleRowSelect();
        },

        getDropDownsData() {
            this.vendorsData = this.getVendorList;
        },

        closeFilterPanel() {
            this.checkFilterOpen = false;
            this.showFilterPanel = false;
        },

        openFilterPannel(selectedFilter) {
            this.checkFilterOpen = true;
            this.showFilterPanel = true;
            this.getTemplatesOwnerFilter(selectedFilter);
            this.getTemplatesSharePublishFilter(selectedFilter);
        },

        singleDuplicateActionButton(rowDetails) {
            this.subTitle = "Template Name";
            this.dialogTitle = "Duplicate template?";
            this.selectedAction = "Duplicate";
            this.toggleDialog = !this.toggleDialog;
            this.$store.commit(
                "reporting/setSelectedItemstoDelete",
                rowDetails
            );
        },

        singalShareActionButton(rowDetails) {
            this.toggleID = rowDetails.templateid;
            this.togglerowSharePopover = !this.togglerowSharePopover;
            this.popOverSelection = "sharePopover";
            this.$store.commit(
                "reporting/setSelectedItemstoDelete",
                rowDetails
            );
        },

        async singleDeleteActionButton(rowDetails) {
            this.subTitle = `This action cannot be undone, and the template
        will be deleted for all users with access`;
            this.dialogTitle = "Delete this template?";
            this.selectedAction = "Delete";
            this.toggleDialog = true;
            // store the selected item in state
            this.$store.commit(
                "reporting/setSelectedItemstoDelete",
                rowDetails
            );
        },

        onClickPanelButton(event) {
            if (event === "Create Template") {
                this.addTemplates();
            } else if (event === "Delete") {
                this.deleteTemplates();
            } else if (event === "Share") {
                this.shareTemplates();
            } else if (event === "Duplicate") {
                this.duplicateTemplates();
            }
        },

        addTemplates() {
            this.$router.push({
                name: "Reporting Create Template",
            });
        },

        // editTemplates() {
        //     const selectedItems =
        //         this.$store.state.reporting.deleteSelectedItems;
        //     const checkData = Array.isArray(selectedItems);
        //     if (checkData) {
        //         const totalLen = selectedItems.length;
        //         for (let i = 0; i < totalLen; i++) {
        //             this.editSelectedItems.push(selectedItems[i].templateid);
        //         }
        //     } else {
        //         this.editSelectedItems.push(selectedItems.templateid);
        //     }
        //     const listids = this.editSelectedItems.join(",");
        //     this.editSelectedItems = [];
        //     // window.location.href = `/settings/templates/create?templateId=${listids}`;
        //     this.$router.push(`/settings/templates/create?templateId=${listids}`);
        // },
        singleEditActionButton(rowDetails) {
            this.$router.push(`/settings/templates/create?templateId=${rowDetails.templateid}`);
        },
        singleViewActionButton(rowDetails) {
            this.$router.push(`/settings/templates/create?templateId=${rowDetails.templateid}`);
        },

        closeDialogPopup() {
            this.toggleDialog = false;
            this.toggleSharePopover = false;
            // this.reset();
        },

        async deleteTemplates() {
            this.subTitle = `This action cannot be undone, and the template
        will be deleted for all users with access`;
            this.dialogTitle = "Delete this template?";
            this.selectedAction = "Delete";
            this.toggleDialog = !this.toggleDialog;
        },

        async deletePopupModal() {
            this.toggleDialog = false;
            this.showLoader = true;
            const selectedItems =
                this.$store.state.reporting.deleteSelectedItems;
            const checkData = Array.isArray(selectedItems);
            if (checkData) {
                selectedItems.forEach((item) => {
                    this.deleteSelectedItems.push(item.templateid);
                });
            } else {
                this.deleteSelectedItems.push(selectedItems.templateid);
            }
            const listids = this.deleteSelectedItems.join(",");
            let response = await deleteTemplateData(listids);
            await this.$store.dispatch(REPORTING_ACTIONS.FETCH_TEMPLATE_OWNER_LIST);
            const obj = {
                response,
                ntfnMsg: "The Template has been deleted.",
                ntfnType: "informational",
                ntfnKey: "informational-example",
            };
            this.notificationResponse(obj);
            this.deleteSelectedItems = [];
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.reset();
        },

        async emitSaveModalPopup({ textFieldValue }) {
            this.toggleDialog = false;
            const selectedItems =
                this.$store.state.reporting.deleteSelectedItems;
            const checkData = Array.isArray(selectedItems);
            if (checkData) {
                const totalLen = selectedItems.length;
                for (let i = 0; i < totalLen; i++) {
                    this.duplicateSelectedItems.push(
                        selectedItems[i].templateid
                    );
                }
            } else {
                this.duplicateSelectedItems.push(selectedItems.templateid);
            }
            const listids = this.duplicateSelectedItems.join(",");
            const itemToDuplicate = {
                templateId: listids,
                templateName: textFieldValue,
            };
            let response = await duplicateTemplateData(itemToDuplicate);
            const obj = {
                response,
                ntfnMsg: "The Template has been duplicated.",
                ntfnType: "success",
                ntfnKey: "success-default",
            };
            this.notificationResponse(obj);
            this.duplicateSelectedItems = [];
            this.reset();
        },

        async notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
        }) {
            if (response.isError == false) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.reset();
                this.page = 1;
                await this.commonTemplateFun();
                this.paginationComponentKey += 1;
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.selectMultiRowData.length = 0;
                this.notificationKey = "error-default";
                this.notificationMessage = response.data.message;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },

        async commonTemplateFun() {
            await this.filterTemplateList({
                searchOwner: '',
                searchStatus: '',
                searchTemplate: this.searchFilterText,
                orderByColumn: this.orderByColumn,
            });
            this.counterDetails.count = 0;
            this.showLoader = false;
        },

        reset() {
            let items = document.querySelectorAll(
                "input[type=checkbox]:checked"
            );
            for (var i = 0; i < items.length; i++) {
                if (items[i].type == 'checkbox') {
                    items[i].checked = false;
                }
            }
            this.$refs.fiterpanel.resetSelectedValue();
            this.searchFilterText = "";
            this.checkboxFilterOwner = [],
            this.checkboxFilterSharedPublish = [],
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
        },
        

        shareTemplates() {
            this.popOverSelection = "sharePopover";
            this.toggleSharePopover = true;
        },

        duplicateTemplates() {
            this.subTitle = "Template Name";
            this.dialogTitle = "Duplicate template?";
            this.selectedAction = "Duplicate";
            this.toggleDialog = !this.toggleDialog;
        },

        async clickOnShareData() {
            this.toggleModalComponent = true;
            this.toggleSharePopover = false;
            this.showLoader = true;
            let templateId;
            let array = [];
            const selectedItems =
                this.$store.state.reporting.deleteSelectedItems;
            const checkData = Array.isArray(selectedItems);
            this.getShareTemplateData = [];

            if (checkData == true) {
                if (selectedItems.length == 1) {
                    selectedItems.forEach(async (item) => {
                        templateId = item.templateid;
                        array = item;
                    });
                }
            } else if (checkData == false) {
                templateId = selectedItems.templateid;
                array = selectedItems;
            }
            if (array.isshare == true) {
                await this.fetchSharedTemplateInformation(templateId);
                let response = this.getShareTemplateInformation.data;
                if (response.length > 0) {
                    response.forEach((item) => {
                        var obj = {};
                        obj.name = item.email;
                        if (item.isedit == true) {
                            obj.access = "Edit";
                        } else if (item.isedit == false) {
                            obj.access = "Read Only";
                        }
                        obj.button = "delete";
                        this.getShareTemplateData.push(obj);
                    });
                }
            }
            this.showLoader = false;
        },

        async saveModalComponent() {
            this.showLoader = true;
            let array = [];
            const selectedItems =
                this.$store.state.reporting.deleteSelectedItems;
            const checkData = Array.isArray(selectedItems);
            if (checkData) {
                selectedItems.forEach((item) => {
                    array.push(item.templateid);
                });
            } else if (checkData == false) {
                array.push(selectedItems.templateid);
            }

            let id = array;
            this.addPermission.forEach((result) => {
                this.removePermission.forEach((Element, index) => {
                    if (result.email == Element.email) {
                        this.removePermission.splice(index, 1);
                    }
                });
            });
            let data = {
                ids: id,
                addPermission: this.addPermission,
                removePermission: this.removePermission,
            };
            let response = await shareTemplate(data);
            const obj = {
                response,
                ntfnMsg: "The template has been shared.",
                ntfnType: "success",
                ntfnKey: "success-default",
            };
            this.notificationResponse(obj);
            this.addPermission = [];
            this.removePermission = [];
            this.showLoader = false;
            this.toggleModalComponent = false;
            this.toggleSharePopover = false;
            this.selectUser = [];
            this.accessInput = "";
        },

        clickOnPublishData() {
            this.toggleSharePopover = false;
            this.subTitle = `Are you sure you'd like to publish this template? This will make the template available for all users.`;
            this.dialogTitle = "Publish template?";
            this.selectedAction = "Publish";
            this.toggleDialog = true;
        },

        async publishYes() {
            this.toggleDialog = false;
            this.showLoader = true;
            const selectedItems =
                this.$store.state.reporting.deleteSelectedItems;
            const checkData = Array.isArray(selectedItems);
            if (checkData) {
                selectedItems.forEach((item) => {
                    this.deleteSelectedItems.push(item.templateid);
                });
            } else {
                this.deleteSelectedItems.push(selectedItems.templateid);
            }
            const templateid = this.deleteSelectedItems.join(",");
            let isPublish = true;
            var object = {
                templateid: templateid,
                publish: isPublish,
            };
            let response = await publishTemplate(
                object.templateid,
                object.publish
            );
            const obj = {
                response,
                ntfnMsg: "The template has been published.",
                ntfnType: "success",
                ntfnKey: "success-default",
            };
            this.notificationResponse(obj);
            this.showLoader = false;
            this.toggleSharePopover = false;
            this.deleteSelectedItems = [];
            this.reset();
        },
        disableRowIcons(params) {
            this.editActionButton = params === "singleSelect" ? true : false;
            this.shareActionButton = params === "singleSelect" ? true : false;
            this.deleteActionButton = params === "singleSelect" ? true : false;
            this.viewActionButton = params === "singleSelect" ? true : false;
            this.duplicateActionButton =
                params === "singleSelect" ? true : false;
        },
        disableTopIcons(params) {
            this.topDuplicateIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
            this.topShareIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
            this.topDeleteIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
        },
        handleRowSelect() {
            const data = Array.from(this.itemTotalCount);
            // const data = this.templatesRow.filter((el) => el.checked);
            this.$store.commit("reporting/setSelectedItemstoDelete", data);
            if (data.length === 1) {
                this.disableRowIcons("singleSelect");
                this.disableTopIcons("singleSelect");
                if (!data[0].isduplicate) {
                   this.topDuplicateIcon[0].isDisabled = true; 
                }
            }
            if (data.length > 1) {
                this.disableRowIcons("singleSelect");
                this.disableTopIcons("singleSelect");
                this.topDuplicateIcon[0].isDisabled = true;
            }
            if (!data.length) {
                this.disableRowIcons("noSelect");
                this.disableTopIcons("noSelect");
            }

            // Delete
            const disableDelete =
                data.length && data.some((item) => item.isdelete === false);
            if (!disableDelete && disableDelete !== 0) {
                this.topDeleteIcon[0].isDisabled = false;
            } else {
                this.topDeleteIcon[0].isDisabled = true;
            }
            // Share
            const disableShare =
                data.length && data.some((item) => item.isshare === false);
            if (!disableShare && disableShare !== 0) {
                this.topShareIcon[0].isDisabled = false;
            } else {
                this.topShareIcon[0].isDisabled = true;
            }
        },
        handleMultiSelection(event) {
            for (let i = 0; i < this.templatesRow.length; i++) {
                this.$set(
                    this.templatesRow[i],
                    "checked",
                    event.target.checked
                );
            }
            // this.handleRowSelect();
        },

        handleRowSelectEvent(rowIndex, isChecked, row) {
            this.$set(this.templatesRow[rowIndex], "checked", isChecked);
            
            if (isChecked) {
                this.itemTotalCount.add(row);
            } else {
                this.itemTotalCount.delete(row);
            }
            this.handleRowSelect(row);
            // console.log(this.itemTotalCount, 'this.itemTotalCount')
            this.counterDetails.count = this.itemTotalCount.size;
        },

        async filterTemplateList({
            page = this.page,
            pageSize = this.pageSize,
            searchOwner = this.selectedOwnerItems,
            searchStatus = this.templateSharedPublishFilter,
            searchTemplate = this.searchFilterText,
            orderByColumn = this.orderByColumn,

            isAscending = this.sortType,
            prevSelectedItems = this.prevSelectedItems,
        }) {
            this.showLoader = true;
            const templateListParams = {
                page,
                pageSize,
                searchOwner,
                searchStatus,
                searchTemplate,
                orderByColumn,
                isAscending,
            };
            await this.$store.dispatch(
                REPORTING_ACTIONS.FETCH_TEMPLATE_LIST,
                templateListParams
            );
            this.getTemplateResponse(prevSelectedItems);
            this.showLoader = false;
            // this.itemTotalCount.clear();
            // this.counterDetails.count = 0;
        },

        clearAllHandleSelectEventCheckox() {
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.disableRowIcons("noSelect");
            this.disableTopIcons("noSelect");
        },

        async multiSelectFilterChange(event, value, headerTypeDetails) {
            this.page = 1;
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader == headerTypeDetails) {
                    for (let j = 0; j < this.filterObject[i].filterList.length; j++) {
                        if (this.filterObject[i].filterList[j].lable == value.lable) {
                            this.filterObject[i].filterList[j].isChecked = event;
                        }
                    }
                }
            }
            this.clearAllHandleSelectEventCheckox();
            if (event == true) {
                if (headerTypeDetails == "Owner") {
                    this.checkboxFilterOwner.push(value.lable);
                } else if (headerTypeDetails == "Shared/Public") {
                    this.checkboxFilterSharedPublish.push(value.lable);
                }
                this.selectedOwnerItems = this.checkboxFilterOwner.join(",");
                this.templateSharedPublishFilter = this.checkboxFilterSharedPublish.join(",");
                this.filterTemplateList({
                    searchOwner: this.selectedOwnerItems,
                    searchStatus: this.templateSharedPublishFilter,
                    searchTemplate: this.searchFilterText,
                    prevSelectedItems: []
                });
                this.paginationComponentKey += 1;
            } else if (event == false) {
                if (headerTypeDetails == "Owner") {
                    for (let i = 0; i < this.checkboxFilterOwner.length; i++) {
                        if (this.checkboxFilterOwner[i] == value.lable) {
                            this.checkboxFilterOwner.splice(i, 1);
                        }
                    }
                } else if (headerTypeDetails == "Shared/Public") {
                    for (let i = 0; i < this.checkboxFilterSharedPublish.length; i++) {
                        if (this.checkboxFilterSharedPublish[i] == value.lable) {
                            this.checkboxFilterSharedPublish.splice(i, 1);
                        }
                    }
                }
                this.selectedOwnerItems = this.checkboxFilterOwner.join(",");
                this.templateSharedPublishFilter = this.checkboxFilterSharedPublish.join(",");
                this.filterTemplateList({
                    searchOwner: this.selectedOwnerItems,
                    searchStatus: this.templateSharedPublishFilter,
                    searchTemplate: this.searchFilterText,
                    prevSelectedItems: []
                });
                this.paginationComponentKey += 1;
            }
            this.prevSelectedItems = 0;
            this.counterDetails.count = 0;
            this.clearCheckboxFilter = true;
        },

        async searchFilter(searchText) {
            this.clearAllHandleSelectEventCheckox();
            this.searchFilterText = searchText;
            this.page = 1;
            this.paginationComponentKey += 1;
            this.filterTemplateList({
                searchOwner: this.selectedOwnerItems,
                searchStatus: this.templateSharedPublishFilter,
                searchTemplate: this.searchFilterText,
                prevSelectedItems: []
            });
            this.prevSelectedItems = 0;
            this.counterDetails.count = 0;
            this.clearCheckboxFilter = true;
        },
        clearTextFilter() {
            this.clearAllHandleSelectEventCheckox();
            this.searchFilterText = "";
            this.page = 1;
            this.paginationComponentKey += 1;
            this.filterTemplateList({
                searchOwner: this.selectedOwnerItems,
                searchStatus: this.templateSharedPublishFilter,
                searchTemplate: this.searchFilterText,
                page: this.page,
                pageSize: this.pageSize,
                prevSelectedItems: this.clearCheckboxFilter ? [] : this.itemTotalCount
            });
        },

        clearAllFilters() {
            this.page = 1;
            this.paginationComponentKey += 1;
            this.clearCheckboxFilter = true;
            this.checkboxFilterOwner.length = 0;
            this.checkboxFilterSharedPublish.length = 0;
            this.$store.commit("reporting/setSelectedItemstoDelete", []);
            this.filterTemplateList({
                searchOwner: "",
                searchStatus: "",
                searchTemplate: "",
                prevSelectedItems: []
            });
            this.selectedOwnerItems = '';
            this.templateSharedPublishFilter = '',
            this.prevSelectedItems = 0;
            this.clearAllHandleSelectEventCheckox();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";

.isFilterOpen {width: 82%}

.templates-header {
    margin-top: 16px;
    @include mds-level-2-heading($bold: false);
}

.templatesTable {
    margin-top: 13px;

    .template_table {
        height: auto;
        max-height: 70vh;
        overflow: auto;
        min-height: 64vh;
    }
}

.tableDataRow {
    padding: 0;
}

.leftBlock {
    width: 94%;
    position: relative;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;
}

.rightBlock {
    width: 55px;
    padding: 0;
    margin-left: calc(100vw - 55px - var(--menuWidth));
    min-height: 100vh;
    border: 1px solid #979797;
    border-top: 1px solid #cccccc;
    position: fixed;
    z-index: 1;
    background-color: white;
}

.showFilterRightBlock {
    width: 292px;
    margin-left: calc(100vw - 292px - var(--menuWidth));
    position: fixed;
    z-index: 1;
}

.showFilterLeftBlock {
    max-width: 96%;
    margin-left: 16px;
    margin-right: 71px;
}
.actionButton {
    margin-left: 5px;
    margin-right: 5px;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}

.move-cancel-save-button-container {
    margin-right: calc(292px - 55px);
}

.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
    margin-right: calc(292px - 55px);
}

.pagination-component {
    position: fixed;
    width: calc(100vw - 88px - var(--menuWidth));
    bottom: 0;
}
</style>
